import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { generateBaseTheme } from '@medignition/medignition-ui';
import '@carbon/react/index.scss';

import store from 'store';
import { baseTheme } from 'theme';
import Root from 'components/Root';

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <ThemeProvider theme={generateBaseTheme(baseTheme)}>
      <Router>
        <Root />
      </Router>
    </ThemeProvider>
  </Provider>
);
