import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  AUTHENTICATED,
  LOGIN,
  AUTHENTICATION_FAILED,
  RESET_ERRORS,
  SET_IS_LOGIN_TRUE,
  RESET_PASSWORD,
  SET_PASSWORD,
  LOGOUT,
  RESTORE_SESSION,
} from './consts';

export const login = createAction(LOGIN);
export const logout = createAction(LOGOUT);
export const authenticationFailed = createAction(AUTHENTICATION_FAILED);
export const authenticated = createAction(AUTHENTICATED);
export const resetErrors = createAction(RESET_ERRORS);
export const setIsLoginTrue = createAction(SET_IS_LOGIN_TRUE);
export const setPassword = createAction(SET_PASSWORD);
export const resetPassword = createAction(RESET_PASSWORD);

export const restoreSession = createAlertAction(RESTORE_SESSION);
