import { useEffect, useRef } from 'react';

import { useAsyncState } from './useAsyncState';

export const useMobileDetect = (size = 1024) => {
  const [mobile, setMobile] = useAsyncState(window?.innerWidth < size);
  const ref = useRef(mobile);

  useEffect(() => {
    const cb = () => {
      const current = window?.innerWidth < size;

      if (ref.current === current) return;

      window.requestAnimationFrame(() => {
        ref.current = current;
        setMobile(current);
      });
    };

    window.addEventListener('resize', cb);

    return () => window.removeEventListener('resize', cb);
  }, [setMobile, size]);

  return mobile;
};
