import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import PoseAnimateContainer from './utils';
import ExtendedMenu from './ExtendedMenu';
import { AppInfo, Card, AppUrl, Text } from './styles';

const AppCard = ({ app, isOpen, onClick }) => {
  const { name, platform, url, isOnline, payload } = app;

  const hasURL = useCallback(() => {
    const PUBLIC_PLATFORMS = ['Web', 'iOS', 'Android'];
    return url && PUBLIC_PLATFORMS.includes(platform);
  }, [platform, url]);

  return (
    <PoseAnimateContainer pose={isOpen ? 'open' : 'close'} size={payload.length}>
      <Card className={`${isOnline ? 'online' : 'offline'}${isOpen ? ' active' : ''}`} onClick={onClick}>
        <AppInfo>
          <Text $upper $bold>
            {name}
          </Text>
          <Text>{platform}</Text>
        </AppInfo>
        {hasURL() && (
          <AppUrl href={url} target="_blank" rel="noopener noreferrer">
            URL
          </AppUrl>
        )}
      </Card>
      <ExtendedMenu pose={isOpen ? 'open' : 'close'} app={app} />
    </PoseAnimateContainer>
  );
};

AppCard.propTypes = {
  app: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    platform: PropTypes.string,
    isOnline: PropTypes.bool,
    payload: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AppCard;
