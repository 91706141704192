import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { pipe, prop, filter, propOr, length } from 'ramda';

import { ID, NAME, DASHBOARD, RANGE, ITEMS, AIM, DESCRIPTION } from 'store/kpis';
import { useActions, useShouldUpdateApps } from 'components/Root/hooks';

import Card from './Card';
import DashboardItem from './DashboardItem';
import { Container, MainTitle, App, Title } from './styles';

const KPI = () => {
  const { data: apps, lastFetch } = useSelector(prop('apps'));
  const shouldUpdate = useShouldUpdateApps(lastFetch);
  const { fetchApps } = useActions();

  useEffect(() => {
    if (shouldUpdate) {
      fetchApps();
    }
  }, [shouldUpdate, fetchApps]);

  const appsWithKPI = useMemo(() => filter(pipe(propOr([], 'kpis'), length, Boolean))(apps || []), [apps]);

  return (
    <>
      <MainTitle>MEDIGNITION DASHBOARD</MainTitle>
      <Container>
        {appsWithKPI.map((app) => (
          <App key={prop(ID, app)}>
            <Title>{app[NAME]}</Title>
            <div>
              {app.kpis.map((kpi) => (
                <Card key={prop(ID, kpi)} id={prop(ID, kpi)} kpi={kpi} appName={app[NAME]} />
              ))}
            </div>
          </App>
        ))}
        {apps.map(({ [ID]: appId, [DASHBOARD]: dashboard }) =>
          (dashboard || []).map(
            ({ [ID]: id, [NAME]: name, [RANGE]: range, [ITEMS]: items, [AIM]: aim, [DESCRIPTION]: description }) => (
              <DashboardItem key={id} id={appId} name={name} range={range} items={items} aim={aim} description={description} />
            )
          )
        )}
      </Container>
    </>
  );
};

export default KPI;
