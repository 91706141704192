import { takeEvery, put, call } from 'redux-saga/effects';
import api from 'api';

import dictionary from 'utils/dictionary.json';
import { withAlert } from 'store/alerts';

import {
  AUTHENTICATION_FAILED,
  AUTHENTICATED,
  LOGIN,
  RESET_PASSWORD_LINK_FAILED,
  RESET_PASSWORD_LINK_SENT,
  RESET_PASSWORD,
  SET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESTORE_SESSION,
  RESTORE_SESSION_SUCCESS,
} from './consts';
import { logout } from './actions';

function* login({ payload }) {
  try {
    const response = yield call(api.login, payload);

    if (response.error || !response.token) {
      const message = response.error.message || response.error;
      yield put({ type: AUTHENTICATION_FAILED, payload: message });
    } else {
      yield put({ type: AUTHENTICATED, payload: response });
    }
  } catch (error) {
    yield put({ type: AUTHENTICATION_FAILED, payload: dictionary.defaultError });
  }
}

function* resetPassword({ payload }) {
  try {
    const response = yield call(api.sendResetLink, payload);

    if (response.error) {
      yield put({ type: RESET_PASSWORD_LINK_FAILED, payload: response.error });
    } else {
      yield put({ type: RESET_PASSWORD_LINK_SENT, payload: response });
    }
  } catch (error) {
    yield put({ type: RESET_PASSWORD_LINK_FAILED, payload: dictionary.defaultError });
  }
}

function* setPassword({ payload }) {
  try {
    const response = yield call(api.resetPassword, payload);

    if (response.error) {
      yield put({ type: RESET_PASSWORD_FAILED, payload: response.error.message || response.error });
    } else {
      yield put({ type: RESET_PASSWORD_SUCCESS, payload: response.success });
    }
  } catch (error) {
    yield put({ type: RESET_PASSWORD_FAILED, payload: error.message || error });
  }
}

function* restoreSession() {
  try {
    const data = yield call(api.restore);

    if (data.error) {
      yield put(logout());

      return { error: data.error };
    }

    yield put({ type: RESTORE_SESSION_SUCCESS, payload: data });

    return { success: true };
  } catch (e) {
    yield put(logout());

    return { error: e.message || 'Oops, error' };
  }
}

export default function* main() {
  yield takeEvery(LOGIN, login);
  yield takeEvery(RESET_PASSWORD, resetPassword);
  yield takeEvery(SET_PASSWORD, setPassword);
  yield takeEvery(RESTORE_SESSION, withAlert(restoreSession));
}
