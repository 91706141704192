import styled from 'styled-components';
import { PopoverContent } from '@carbon/react';

import { baseTheme } from 'theme';
import { ReactComponent as InfoIcon } from 'assets/svg-icons/info.svg';

export const Container = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: ${({ $withChildren }) => ($withChildren ? '110px ' : '50px')} 1fr;
  row-gap: 24px;
  width: 100%;
  height: 100%;
  padding: 10px 20px 20px;
`;

export const Head = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  z-index: 1;
`;

export const Button = styled.button`
  height: 40px;
  width: 40px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5em;
`;

export const Title = styled.h1`
  width: fit-content;
  font-size: ${baseTheme.font.sizes.label};
  font-weight: ${baseTheme.font.weights.bold};
  text-transform: uppercase;
`;

export const Icon = styled(InfoIcon)`
  height: 18px;
  width: 18px;
  min-width: 18px;
  fill: #000;
  cursor: pointer;
`;

export const PopperContent = styled(PopoverContent)`
  background: #fff;
  padding: 10px 20px;
  text-align: left;
`;
