import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { path } from 'ramda';

import { useAlerts } from 'store/alerts/hooks';
import Spinner from 'components/FullScreenSpinner';

import InitData from './InitData';
import { useActions } from '../hooks';

const InitApp = ({ children }) => {
  const { restoreSession } = useActions();
  const { action, id, loading } = useAlerts(restoreSession);
  const auth = useSelector(path(['session', 'auth']));
  const user = useSelector(path(['session', 'user']));

  useEffect(() => {
    if (localStorage.getItem('token') && !id) action();
  }, [action, id]);

  return (
    <>
      {loading && <Spinner />}
      {!(auth && !user) && <InitData>{children}</InitData>}
    </>
  );
};

InitApp.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InitApp;
