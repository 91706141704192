/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { CHF, PERCENTAGE, YEARLY, MONTHLY } from 'store/kpis';

import { FORMAT_DATE } from './consts';
import { prettyValue } from './utils';
import { Li } from './styles';

const Tooltip = ({ date, prevDate, value, values, label, unit, period }) => {
  const [dateString, prefix, postFix, prevLabel] = useMemo(
    () => [
      FORMAT_DATE.format(new Date(prevDate || date)),
      (unit.toUpperCase() === CHF && 'CHF ') || '',
      (unit.toUpperCase() === PERCENTAGE.toUpperCase() && '%') || '',
      (period === YEARLY && 'Previous year') || (period === MONTHLY && 'Previous month') || 'Previous',
    ],
    [date, period, prevDate, unit]
  );
  const items = useMemo(
    () =>
      values
        ? values.map((val, i) => [
            i === 0 ? label : prevLabel,
            [prefix, prettyValue(val || null, unit), postFix].join(''),
            i === 0 ? '#0060fb' : '#c5c5c5',
          ])
        : [[label, [prefix, prettyValue(value || null, unit), postFix].join(''), '#0060fb']],
    [values, label, prefix, value, unit, postFix, prevLabel]
  );

  return (
    <ul className="multi-tooltip">
      <li>
        <div className="datapoint-tooltip">
          <div className="label">
            <p>{dateString}</p>
          </div>
        </div>
      </li>
      {items.map(([l, v, c], key) => (
        <Li key={key} $color={c}>
          <div className="datapoint-tooltip">
            <div className="label">
              <p>{l}:</p>
            </div>
            <p className="value">{v}</p>
          </div>
        </Li>
      ))}
    </ul>
  );
};

Tooltip.defaultProps = {
  date: null,
  prevDate: null,
  values: null,
  unit: '',
  period: '',
};
Tooltip.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  prevDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  value: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.number.isRequired),
  label: PropTypes.string.isRequired,
  unit: PropTypes.string,
  period: PropTypes.string,
};

export default Tooltip;
