import { takeEvery, put, call } from 'redux-saga/effects';
import api from 'api';

import dictionary from 'utils/dictionary.json';

import { FETCH_APPS, FETCH_APPS_SUCCESS, FETCH_APPS_FAILED, SET_IS_LOADING_TRUE } from './consts';

function* fetchApps() {
  try {
    yield put({ type: SET_IS_LOADING_TRUE });
    const response = yield call(api.fetchApps);

    if (response.error) {
      yield put({ type: FETCH_APPS_FAILED, payload: response.error });
    } else {
      yield put({ type: FETCH_APPS_SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: FETCH_APPS_FAILED, payload: dictionary.defaultError });
  }
}
export default function* main() {
  yield takeEvery(FETCH_APPS, fetchApps);
}
