import {
  AUTHENTICATED,
  AUTHENTICATION_FAILED,
  SET_IS_LOGIN_TRUE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_LINK_FAILED,
  RESET_PASSWORD_LINK_SENT,
  RESTORE_SESSION_SUCCESS,
  LOGOUT,
  RESET_ERRORS,
} from './consts';

import initialState from '../initialState';

export default (state = initialState.session, { type, payload = {} } = {}) => {
  switch (type) {
    case AUTHENTICATED:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        isLogin: false,
        auth: true,
        user: payload,
      };

    case AUTHENTICATION_FAILED:
      localStorage.removeItem('token');
      return {
        ...state,
        auth: !!localStorage.token,
        success: '',
        isLogin: false,
        error: payload,
      };

    case SET_IS_LOGIN_TRUE:
      return {
        ...state,
        isLogin: true,
      };

    case RESET_PASSWORD_LINK_SENT:
      return {
        ...state,
        error: '',
        success: payload.success,
      };

    case RESET_PASSWORD_LINK_FAILED:
      return {
        ...state,
        success: '',
        error: payload,
      };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, error: '', success: payload };

    case RESET_PASSWORD_FAILED:
      return { ...state, success: '', error: payload };

    case RESTORE_SESSION_SUCCESS:
      return { ...state, auth: true, user: payload };

    case RESET_ERRORS:
      return { ...state, success: '', error: '' };

    case LOGOUT:
      localStorage.removeItem('token');
      return { ...initialState.session, auth: false };

    default:
      return state;
  }
};
