import { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { map, pipe } from 'ramda';

import { fetchApps } from 'store/apps/actions';
import { restoreSession } from 'store/session/actions';
import { fetchAlleyeProviderKPI } from 'store/alleyeDashboard/actions';
import { fetchAlleyeProviders, resetAlleyeProviders } from 'store/alleyeProviders/actions';
import { postAlleyeSales } from 'store/alleyeSales/actions';

export function useShouldUpdateApps(lastFetch) {
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    const nextFetch = new Date();
    nextFetch.setTime(new Date(lastFetch).getTime() + 60 * 60 * 1000);

    setShouldUpdate(nextFetch < new Date());
  }, [lastFetch]);

  return shouldUpdate;
}

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      map((action) => pipe(action, dispatch), {
        postAlleyeSales,
        resetAlleyeProviders,
        fetchAlleyeProviders,
        fetchAlleyeProviderKPI,
        fetchApps,
        restoreSession,
      }),
    [dispatch]
  );
};
