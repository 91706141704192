import React from 'react';
import PropTypes from 'prop-types';

import { Container, Item } from './styles';

const Accordion = ({ title, children }) => (
  <Container>
    <Item title={title}>{children}</Item>
  </Container>
);

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Accordion;
