import { takeLatest, takeEvery, takeLeading, call } from 'redux-saga/effects';

import api from 'api';
import { withAlert } from 'store/alerts';

import { FETCH_KPI_HISTORY, FETCH_DASHBOARD_KPI, EXPORT_DASHBOARD_EXCEL } from './types';
import { ID, NAME, FILTER, ITEM, ALL_ITEM } from './consts';

function* fetchKpiHistory({ payload }) {
  const data = yield call(api.getKpiHistory, payload);

  if (data.error) return { error: data.error };

  return { success: data };
}

const getDashboardBody = (data) => ({
  [FILTER]: data[FILTER],
  ...(data[ITEM] && data[ITEM] !== ALL_ITEM && { [ITEM]: data[ITEM] }),
});

function* fetchDashboardKpi({ payload }) {
  return {
    success: yield call(api.getDashboardKpi, payload[ID], payload[NAME], getDashboardBody(payload)),
  };
}

function* exportDashboardExcel({ payload }) {
  yield call(api.exportDashboardExcel, payload[ID], payload[NAME], getDashboardBody(payload));
}

export default function* watchKpis() {
  yield takeLatest(FETCH_KPI_HISTORY, withAlert(fetchKpiHistory));
  yield takeEvery(FETCH_DASHBOARD_KPI, withAlert(fetchDashboardKpi));
  yield takeLeading(EXPORT_DASHBOARD_EXCEL, withAlert(exportDashboardExcel));
}
