import styled from 'styled-components';
import { Accordion, AccordionItem } from '@carbon/react';

import { baseTheme } from 'theme';

export const Container = styled(Accordion)`
  grid-column: 1/-1;
  align-self: flex-start;
`;

export const Item = styled(AccordionItem)`
  background-color: ${baseTheme.colors.white};
  & .cds--accordion__wrapper > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;
  }
`;
