import { cond, equals, T } from 'ramda';

import { MTD, QTD } from './consts';

export const getDateRange = (key) => {
  const now = new Date();
  const [year, month, day] = [now.getFullYear(), now.getMonth(), now.getDate()];
  const end = new Date(year, month, day);

  return cond([
    [equals(MTD), () => [new Date(year, month, 1), end]],
    [equals(QTD), () => [new Date(year, (month > 8 && 9) || (month > 5 && 6) || (month > 2 && 3) || 0, 1), end]],
    [T, () => [new Date(year, 0, 1), end]],
  ])(key);
};
