/* eslint-disable prefer-promise-reject-errors */
import { APPLICATION_TOKEN } from 'utils/constants';
import { GET } from './consts';

export const requestHeaders = () => ({
  'Content-Type': 'application/json',
  AUTHORIZATION: `Bearer ${localStorage.token}`,
  'api-key': APPLICATION_TOKEN,
});

const handleOptions = (data = {}) => {
  const { method = GET, headers = requestHeaders(), body } = data;

  return {
    method,
    headers,
    ...(body && { body: JSON.stringify(body) }),
  };
};

export const wrapperRequest = ({ url, options, contentType }) =>
  new Promise((resolve, reject) => {
    fetch(url, handleOptions(options)).then(
      async (response) => {
        if (contentType) {
          const blob = await response.blob();
          const name = (response.headers.get('Content-Disposition') || '').split('filename=')[1];
          const URL = window.URL || window.webkitURL;
          const href = URL.createObjectURL(blob);
          const a = document.createElement('a');

          a.href = href;
          a.target = '_blank';
          a.rel = 'noopener noreferrer';

          if (name) a.download = name;

          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(href);
          document.body.removeChild(a);

          resolve(name);
        } else {
          resolve(response.json());
        }
      },
      (err) => reject({ type: 'main', error: err })
    );
  });
