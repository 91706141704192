import { wrapperRequest } from './utils';
import { API_URL, GET, POST } from './consts';

export const getKpiHistory = (id) =>
  wrapperRequest({
    url: `${API_URL}/kpis/${id}/history`,
    options: { method: GET },
  });

export const getDashboardKpi = (id, name, body) =>
  wrapperRequest({
    url: `${API_URL}/apps/${id}/dashboards/${name}`,
    options: { method: POST, body },
  });

export const exportDashboardExcel = (id, name, body) =>
  wrapperRequest({
    url: `${API_URL}/apps/${id}/dashboards/${name}/export`,
    options: { method: POST, body },
    contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
