import styled from 'styled-components';
import posed from 'react-pose';

import { Dropdown, DatePicker } from '@carbon/react';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  position: relative;
`;

export const Select = styled(Dropdown)`
  width: 165px;
`;

export const Button = styled.button`
  width: fit-content;
  min-width: 165px;
  height: 40px;
  border: none;
  padding: 0 16px;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const Box = styled(
  posed.div({
    open: {
      height: 'auto',
      transition: { duration: 300 },
    },
    close: {
      height: '0',
      transition: { duration: 300 },
    },
  })
)`
  position: absolute;
  inset: calc(100% + 2px) 0 auto 0;
  overflow: hidden;
`;

export const DateWrapper = styled(DatePicker)`
  & > div {
    display: grid;
    grid-template-columns: 165px 165px;
    column-gap: 2px;
    align-items: center;
  }
  & > div > div,
  & > div > div > div > span > input {
    width: 100%;
    inline-size: 100% !important;
  }
  & > div > div > div > span {
    width: 100%;
  }
`;
