import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { LineChart } from '@carbon/charts-react';
import { Popover } from '@carbon/react';
import PropTypes from 'prop-types';
import '@carbon/charts/styles.css';

import { ID, VALUE, PREV_VALUE, DATE, PERCENTAGE, ALL } from 'store/kpis';
import { ReactComponent as CloseIcon } from 'assets/svg-icons/cross.svg';

import { parseData } from './utils';
import Tooltip from './Tooltip';
import { Container, Head, Button, Wrapper, TitleWrap, Title, Icon, PopperContent } from './styles';

const KpiHistory = ({ appName, items, label, period, unit, onClose, isDashboard, description, children }) => {
  const [key, setKey] = useState(0);
  const data = useMemo(() => parseData(period, items || [], unit), [period, items, unit]);
  const getColor = useCallback((group) => (group.includes('Current') ? '#0060fb' : '#c5c5c5'), []);
  const options = useMemo(() => {
    const isPercentage = unit.toUpperCase() === PERCENTAGE.toUpperCase();
    const title = isDashboard ? unit.toUpperCase() : [label, unit === PERCENTAGE ? '(IN %)' : ''].filter(Boolean).join(' ');

    return {
      axes: {
        bottom: items.length > 1 ? { mapsTo: 'date', scaleType: 'time' } : { mapsTo: 'dateLabel', scaleType: 'labels' },
        left: {
          mapsTo: 'value',
          scaleType: 'linear',
          title: isPercentage ? '' : title,
          ticks: {
            formatter: (tick) => (isPercentage ? `${tick}%` : tick),
          },
        },
      },
      toolbar: {
        enabled: false,
      },
      tooltip: {
        customHTML: (d) =>
          renderToString(
            <Tooltip
              date={d[0].date}
              prevDate={d[0].prevDate}
              value={d[0].value}
              values={d[0].labelValues}
              label={label}
              unit={unit}
              period={period}
            />
          ),
      },
      legend: {
        enabled: period !== ALL,
      },
      height: '100%',
      width: '100%',
      getFillColor: getColor,
      getStrokeColor: getColor,
    };
  }, [isDashboard, unit, label, items.length, period, getColor]);

  const [isPopper, setPopper] = useState(false);
  const onMouseEnter = useCallback(() => setPopper(true), []);
  const onMouseLeave = useCallback(() => setPopper(false), []);

  useEffect(() => {
    let frame;
    const cb = () => {
      if (frame) window.cancelAnimationFrame(frame);

      frame = window.requestAnimationFrame(() => {
        setKey(($) => $ + 1);
      });
    };

    window.addEventListener('resize', cb);

    return () => {
      if (frame) window.cancelAnimationFrame(frame);

      window.removeEventListener('resize', cb);
    };
  }, []);

  return (
    <Container $withChildren={children}>
      <Head>
        {description ? (
          <Popover open={isPopper} align="bottom-start" color="$layer-02">
            <TitleWrap>
              <Title>{isDashboard ? label : appName}</Title>
              <Icon onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
            </TitleWrap>
            <PopperContent>{description}</PopperContent>
          </Popover>
        ) : (
          <Title>{isDashboard ? label : appName}</Title>
        )}
        <Button type="button" onClick={onClose} aria-label="Close Button">
          <CloseIcon />
        </Button>
        {children}
      </Head>
      <Wrapper>
        <LineChart key={key} data={data} options={options} />
      </Wrapper>
    </Container>
  );
};

KpiHistory.defaultProps = { unit: '', period: ALL, children: null, isDashboard: false, description: '' };
KpiHistory.propTypes = {
  appName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      [ID]: PropTypes.string.isRequired,
      [VALUE]: PropTypes.number.isRequired,
      [PREV_VALUE]: PropTypes.number,
      [DATE]: PropTypes.string,
    }).isRequired
  ).isRequired,
  label: PropTypes.string.isRequired,
  period: PropTypes.string,
  unit: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool,
  children: PropTypes.node,
};

export default KpiHistory;
