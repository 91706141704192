import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Select, Option } from './styles';

const CustomDropdown = ({
  selectedOption,
  onSelect,
  list,
  placeholder,
  getOptionId,
  getOptionLabel,
  withBottomMargin,
  className,
}) => {
  const onChange = useCallback(({ currentTarget }) => onSelect(currentTarget.value), [onSelect]);

  return (
    <Select value={selectedOption} onChange={onChange} withBottomMargin={withBottomMargin} className={className}>
      {placeholder && (
        <Option value="" disabled defaultValue>
          {placeholder}
        </Option>
      )}
      {list.map((item) => (
        <Option key={getOptionId(item)} value={getOptionId(item)}>
          {getOptionLabel(item)}
        </Option>
      ))}
    </Select>
  );
};

CustomDropdown.defaultProps = {
  selectedOption: null,
  withBottomMargin: false,
  className: undefined,
};

CustomDropdown.propTypes = {
  withBottomMargin: PropTypes.bool,
  getOptionId: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
};

export default CustomDropdown;
